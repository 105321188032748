import React from 'react';

function Footer() {
  return (
    <React.Fragment>
      <div className='footer'>
        <p>
          Terapia Antibiotica Empirica Manuale Proposta Regionale Versione 1,
          04 novembre 2021; revisione finale 19 marzo 2023
        </p>
        <p>
          Made with <span className='heart'>♥</span> by Asclepeion
        </p>
      </div>
    </React.Fragment>
  );
}

export default Footer;
