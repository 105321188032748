import React from 'react';
import { ShowItem } from '../classes/showItem';
import ShowItems from './ShowItems';
import Progress from './Progress';
import { ErrorDialogContext } from './ErrorDialog';
import { useSystemState } from '../SystemState';

interface PathologyPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function PathologyPanel(props: PathologyPanelProps) {
  const { children, value, index, ...other } = props;

  const [pathologies, setPathologies] = React.useState<ShowItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const state = useSystemState();

  const errorDialogCtx = React.useContext(ErrorDialogContext);

  React.useEffect(() => {
    setLoading(true);
    state.pathologies.then((res) => {
      setPathologies(res.getItems());
      setLoading(false);
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
      setLoading(false);
    });
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          { loading && <Progress />}
          <ShowItems items={pathologies} urlPrefix='/list' showScrollToTop />
        </React.Fragment>
      )}
    </div>
  );
}

export default PathologyPanel;
