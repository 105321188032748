import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import NoPage from './pages/NoPage';
import Search from './pages/Search';
import List from './pages/List';
import Favorites from './pages/Favorites';
import Info from './pages/Info';
import DrugDetails from './pages/DrugDetails';
import PathologyDetails from './pages/PathologyDetails';
import { TopBarContext, TopBarContextProps } from './TopBar';
import InstallPWASafari from './pages/InstallPWASafari';
import { ErrorDialogContext } from './components/ErrorDialog';
import { SystemStateProvider } from './SystemState';
import { HistoryContext, defaultHistoryManager } from './HistoryContext';
import NoteDetails from './pages/NoteDetails';

function App() {
  const [title, setTitle] = React.useState<string>();
  const topBarCtxValue: TopBarContextProps = { title, setTitle };

  const [errorDialogOpen, setErrorDialogOpen] = React.useState<boolean>(false);
  const [errorDialogContent, setErrorDialogContent] = React.useState<string>('');
  const errorDialogCtxValue = { open: errorDialogOpen, setOpen: setErrorDialogOpen, content: errorDialogContent, setContent: setErrorDialogContent };
  const historyContextValue = defaultHistoryManager;

  return (
    <SystemStateProvider>
      <ErrorDialogContext.Provider value={errorDialogCtxValue}>
        <HistoryContext.Provider value={historyContextValue}>
          <TopBarContext.Provider value={topBarCtxValue}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Search />} />
                  <Route path="search" element={<Search />} />
                  <Route path="search/drug/:id" element={<DrugDetails urlPrefix='/search' />} />
                  <Route path="search/pathology/:id" element={<PathologyDetails urlPrefix='/search' />} />
                  <Route path="list" element={<List />} />
                  <Route path="list/:panel" element={<List />} />
                  <Route path="list/drug/:id" element={<DrugDetails urlPrefix='/list' />} />
                  <Route path="list/pathology/:id" element={<PathologyDetails urlPrefix='/list' />} />
                  <Route path="favorites" element={<Favorites />} />
                  <Route path="favorites/drug/:id" element={<DrugDetails urlPrefix='/favorites' />} />
                  <Route path="favorites/pathology/:id" element={<PathologyDetails urlPrefix='/favorites' />} />
                  <Route path="info" element={<Info />} />
                  <Route path="info/note/:id" element={<NoteDetails />} />
                  <Route path="install-pwa-safari" element={<InstallPWASafari />} />
                  <Route path="*" element={<NoPage />} />
                </Route>
                <Route path="*" element={<NoPage />} />
              </Routes>
            </BrowserRouter>
          </TopBarContext.Provider>
        </HistoryContext.Provider>
      </ErrorDialogContext.Provider>
    </SystemStateProvider>
  );
}

export default App;
