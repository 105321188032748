import { ShowItem, ItemManager } from "./showItem";
import { ReactNode } from 'react';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';

export class Pathology implements ShowItem {
    code: number;
    title: string;
    ref: number;
    caption: string;
    description: string;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 5) {
            throw new Error(`Invalid CSV array length for Pathology: ${csvArray.length}`);
        }

        this.code = parseInt(csvArray[0]);
        this.title = csvArray[2];
        this.ref = parseInt(csvArray[1]);
        this.caption = csvArray[3];
        this.description = csvArray[4];
    }

    url(): string {
        return `/pathology/${this.code}`;
    }

    render(): string {
        return this.caption;
    }

    icon(): ReactNode {
        return <CoronavirusIcon />
    }

    id(): string {
        return `pathology-${this.code}`;
    }
}

export class Pathologies implements ItemManager<Pathology> {
    private items: Pathology[] = [];

    addItem(line: string[]) {
        const pathology = new Pathology(line);
        this.items.push(pathology);
    }

    getItems(): Pathology[] {
        return this.items.filter(item => isNaN(item.ref));
    }

    getById(id: number): Pathology | undefined {
        return this.items.find(item => item.code === id);
    }

    getChildren(parentId: number): Pathology[] {
        return this.items.filter(item => item.ref === parentId);
    }
}