import { ShowItem } from "./showItem";
import { SystemDB } from '../SystemState';

export class Favorites {
    constructor(private items: ShowItem[]) {
        this.items = items;
    }

    getItems(): ShowItem[] {
        return this.items;
    }

    addFavorite(item: ShowItem) {
        if(this.isFavorite(item)) return;
        this.items.push(item);
        this.store();
    }

    removeFavorite(item: ShowItem) {
        if(!this.isFavorite(item)) return;
        this.items = this.items.filter(i => i !== item);
        this.store();
    }

    isFavorite(item: ShowItem): boolean {
        return this.items.some(i => i === item);
    }

    store() {
        localStorage.setItem('favorites', JSON.stringify(this.items.map(i => i.id())));
    }
}

export function loadFavorites(db: SystemDB): Promise<Favorites> {
    return new Promise(async (resolve) => {
        try {           
            const favs = JSON.parse(localStorage.getItem('favorites') ?? '[]');
            const items = [];
    
            for(const fav of favs) {
                let item = undefined;
                const [type, code] = fav.split('-');
                switch(type) {
                    case 'drug':
                        item = (await db.drugs).getById(parseInt(code));
                        break;
                    case 'pathology':
                        item = (await db.pathologies).getById(parseInt(code));
                        break;
                    case 'note':
                        item = (await db.notes).getById(parseInt(code));
                        break;
                }
    
                if(item) {
                    items.push(item);
                } else {
                    // This may happen if DB changes or local storage is manually modified
                    console.warn(`Cannot find favorite ${fav}`);
                }
            }
    
            const favorites = new Favorites(items);
            resolve(favorites);
        } catch (error) {
            // We don't raise error here, we just return an empty favorites list
            console.warn(`Cannot load favorites: ${error}`);
            const favorites = new Favorites([]);
            resolve(favorites);
        }
    });
}