import React from "react";

export const searchDefaultPath = '/search';
export const listDefaultPath = '/list';
export const favoritesDefaultPath = '/favorites';
export const infoDefaultPath = '/info';

export class HistoryManager {
  private searchHistory: string[];
  private listHistory: string[];
  private favoritesHistory: string[];
  private infoHistory: string[];
  private lastPages: string[];

  constructor() {
    this.searchHistory = [];
    this.listHistory = [];
    this.favoritesHistory = [];
    this.infoHistory = [];
    this.lastPages = [];
  }

  currentPath(path: string) {
    if (path.startsWith(searchDefaultPath)) {
      this.searchHistory = setHistoryIfDifferent(this.searchHistory, path);
    } else if (path.startsWith(listDefaultPath)) {
      this.listHistory = setHistoryIfDifferent(this.listHistory, path);
    } else if (path.startsWith(favoritesDefaultPath)) {
      this.favoritesHistory = setHistoryIfDifferent(this.favoritesHistory, path);
    } else if (path.startsWith(infoDefaultPath)) {
      this.infoHistory = setHistoryIfDifferent(this.infoHistory, path);
    }
    this.lastPages = setHistoryIfDifferent(this.lastPages, path);
    // we only keep the last 10 pages
    if (this.lastPages.length > 10) {
      this.lastPages = this.lastPages.slice(this.lastPages.length - 10);
    }
  }

  back(currentPath: string): string {
    let page: string | undefined;
    if (currentPath.startsWith(searchDefaultPath)) {
      page = popTwice(this.searchHistory, currentPath) ?? searchDefaultPath;
    } else if (currentPath.startsWith(listDefaultPath)) {
      page = popTwice(this.listHistory, currentPath) ?? listDefaultPath;
    } else if (currentPath.startsWith(favoritesDefaultPath)) {
      page = popTwice(this.favoritesHistory, currentPath) ?? favoritesDefaultPath;
    } else if (currentPath.startsWith(infoDefaultPath)) {
      page = popTwice(this.infoHistory, currentPath) ?? infoDefaultPath;
    }
    popTwice(this.lastPages, currentPath);
    return page ?? searchDefaultPath;
  }

  backBrowser(): string | null {
    const lastPage = this.getLastPage();
    if (lastPage) {
      const page = this.back(lastPage);

      if (lastPage.startsWith(searchDefaultPath) && this.searchHistory.length > 1 && this.searchHistory[this.searchHistory.length - 1] === lastPage) {
        this.searchHistory = this.searchHistory.slice(0, this.searchHistory.length - 1);
      } else if (lastPage.startsWith(listDefaultPath) && this.listHistory.length > 1 && this.listHistory[this.listHistory.length - 1] === lastPage) {
        this.listHistory = this.listHistory.slice(0, this.listHistory.length - 1);
      } else if (lastPage.startsWith(favoritesDefaultPath) && this.favoritesHistory.length > 1 && this.favoritesHistory[this.favoritesHistory.length - 1] === lastPage) {
        this.favoritesHistory = this.favoritesHistory.slice(0, this.favoritesHistory.length - 1);
      } else if (lastPage.startsWith(infoDefaultPath) && this.infoHistory.length > 1 && this.infoHistory[this.infoHistory.length - 1] === lastPage) {
        this.infoHistory = this.infoHistory.slice(0, this.infoHistory.length - 1);
      }

      this.currentPath(page);
      return page;
    }
    return null;
  }

  private getLastPage(): string | undefined {
    if (this.lastPages.length <= 1) {
      return undefined;
    }
    return this.lastPages[this.lastPages.length - 2];
  }
}

export const HistoryContext = React.createContext<HistoryManager | null>(null);

function setHistoryIfDifferent(history: string[], path: string): string[] {
  if (history.length === 0 || history[history.length - 1] !== path) {
    return [...history, path];
  }
  return history;
};

// we need to pop twice if the current page is also in the history
function popTwice(history: string[], currentPath: string): string | undefined {
  const l = history.pop();
  if (l === currentPath) {
    return history.pop();
  }
  return l;
}

export const defaultHistoryManager = new HistoryManager();
