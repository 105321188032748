import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import DrugsPanel from '../components/DrugsPanel';
import PathologyPanel from '../components/PathologyPanel';
import { TopBarContext } from '../TopBar';
import { useNavigate, useParams } from 'react-router-dom';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function List() {
  const [value, setValue] = React.useState(0);

  const params = useParams();
  const navigate = useNavigate();

  const topBarCtx = React.useContext(TopBarContext);

  React.useEffect(() => {
    topBarCtx?.setTitle('Elenco');
  }, []);

  React.useEffect(() => {
    switch (params.panel) {
      case 'drugs':
        setValue(0);
        break;
      case 'pathologies':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [params.panel]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      navigate('/list/drugs');
    } else if (newValue === 1) {
      navigate('/list/pathologies');
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Farmaci" {...a11yProps(0)} />
          <Tab label="Patologie" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <DrugsPanel value={value} index={0} key={'drugs'} />
      <PathologyPanel value={value} index={1} key={'pathologies'} />
    </React.Fragment>
  );
}

export default List;
