import { BottomNavigation, BottomNavigationAction, Paper, styled } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListIcon from '@mui/icons-material/List';
import InfoIcon from '@mui/icons-material/Info';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isPWA } from './utils/displayMode';
import { iOSPWABottomBarHeight, iOSPWABottomPadding } from './config';
import { isiOS } from './utils/device';
import { HistoryContext, favoritesDefaultPath, infoDefaultPath, listDefaultPath, searchDefaultPath } from './HistoryContext';

function BottomBar() {
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  const paddingBottomNumber = isPWA() && isiOS() ? iOSPWABottomPadding : 0;
  const paddingBottom = paddingBottomNumber + 'px';

  const [value, setValue] = React.useState(0);
  const [searchPath, setSearchPath] = React.useState<string>(searchDefaultPath);
  const [listPath, setListPath] = React.useState<string>(listDefaultPath);
  const [favoritesPath, setFavoritesPath] = React.useState<string>(favoritesDefaultPath);
  const [infoPath, setInfoPath] = React.useState<string>(infoDefaultPath);

  const historyCtx = React.useContext(HistoryContext);

  const location = useLocation();
  const navigate = useNavigate();

  const clickOnActiveTab = () => {
    setTimeout(() => {
      if (value === 0) {
        navigate(searchDefaultPath);
      } else if (value === 1) {
        navigate(listDefaultPath);
      } else if (value === 2) {
        navigate(favoritesDefaultPath);
      } else if (value === 3) {
        navigate(infoDefaultPath);
      }
    }, 100);
  }

  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname === '') {
      navigate(searchDefaultPath);
    } else if (location.pathname.startsWith(searchDefaultPath)) {
      setValue(0);
      setSearchPath(location.pathname + location.search);
    } else if (location.pathname.startsWith(listDefaultPath)) {
      setValue(1);
      setListPath(location.pathname + location.search);
    } else if (location.pathname.startsWith(favoritesDefaultPath)) {
      setValue(2);
      setFavoritesPath(location.pathname + location.search);
    } else if (location.pathname.startsWith(infoDefaultPath)) {
      setValue(3);
      setInfoPath(location.pathname + location.search);
    }

    historyCtx?.currentPath(location.pathname + location.search);
  }, [location]);

  return (
    <React.Fragment>
      <Offset style={
        {
          height: paddingBottomNumber > 0 ? (paddingBottomNumber+iOSPWABottomBarHeight)+'px' : undefined,
        }
      } />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} style={
        {
          paddingBottom,
        }
      }>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={Link} to={searchPath} label="Cerca"
            onClick={() => {
              if (value === 0) {
                clickOnActiveTab();
              }
            }}
            icon={<SearchIcon />} />
          <BottomNavigationAction component={Link} to={listPath} label="Elenco"
            onClick={() => {
              if (value === 1) {
                clickOnActiveTab();
              }
            }}
            icon={<ListIcon />} />
          <BottomNavigationAction component={Link} to={favoritesPath} label="Preferiti"
            onClick={() => {
              if (value === 2) {
                clickOnActiveTab();
              }
            }}
            icon={<FavoriteIcon />} />
          <BottomNavigationAction component={Link} to={infoPath} label="Info"
            onClick={() => {
              if (value === 3) {
                clickOnActiveTab();
              }
            }}
            icon={<InfoIcon />} />
        </BottomNavigation>
      </Paper>
    </React.Fragment>
  );
}

export default BottomBar;
