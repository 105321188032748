import { ShowItem, ItemManager } from "./showItem";
import { ReactNode } from 'react';
import InfoIcon from '@mui/icons-material/Info';

export class Note implements ShowItem {
    code: number;
    title: string;
    description: string;
    hide: boolean;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 4) {
            throw new Error(`Invalid CSV array length for Note: ${csvArray.length}`);
        }

        this.code = parseInt(csvArray[0]);
        this.title = csvArray[2];
        this.description = csvArray[3];
        this.hide = csvArray[1] === "1";
    }

    url(): string {
        return `/note/${this.code}`;
    }

    render(): string {
        return "";
    }

    icon(): ReactNode {
        return <InfoIcon />
    }

    id(): string {
        return `note-${this.code}`;
    }
}

export class Notes implements ItemManager<Note> {
    private items: Note[] = [];

    addItem(line: string[]) {
        const note = new Note(line);
        this.items.push(note);
    }

    getItems(): Note[] {
        return this.items.filter(item => !item.hide);
    }

    getById(id: number): Note | undefined {
        return this.items.find(item => item.code === id);
    }
}