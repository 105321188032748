import { AppBar, Box, Button, IconButton, Toolbar, Typography, styled } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { isPWA } from './utils/displayMode';
import { useInstall } from './hooks/useInstall';
import { isMobileSafari } from './utils/device';
import { HistoryContext } from './HistoryContext';

export interface TopBarContextProps {
  title: string | undefined;
  setTitle: (title: string) => void;
}

export const TopBarContext = React.createContext<TopBarContextProps | null>(null);

function TopBar() {
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  const [isInstallVisible, setInstallVisibleState] = React.useState(isMobileSafari());

  const navigate = useNavigate();
  const location = useLocation();
  const [prompt, promptToInstall] = useInstall();

  const topBarCtx = React.useContext(TopBarContext);
  const historyCtx = React.useContext(HistoryContext);

  const goBack = () => {
    const page = historyCtx?.back(location.pathname + location.search);
    navigate(page ?? '/');
  };

  const onInstallClick = () => {
    if (isMobileSafari()) {
      navigate('/install-pwa-safari');
    } else {
      promptToInstall();
    }
  }

  React.useEffect(() => {
    if (prompt) {
      setInstallVisibleState(true);
    }
  }, [prompt]);

  React.useEffect(() => {
    const onPopState = (e: PopStateEvent) => {
      // handle back button
      const page = historyCtx?.backBrowser();
      if (page) {
        navigate(page ?? '/');
      }
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={
        {
          background: 'var(--white)',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px',
          color: 'var(--primary)',
        }
      }>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => {
              goBack();
            }}
            sx={{ mr: 2 }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            { topBarCtx?.title ?? 'DrugTip' }
          </Typography>
          { (!isPWA() && isInstallVisible) && <Button color="inherit" onClick={onInstallClick}>Install</Button> }
        </Toolbar>
      </AppBar>
      <Offset />
    </Box>
  );
}

export default TopBar;
