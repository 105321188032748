import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Fab } from "@mui/material";
import React from "react";
import { isPWA } from "../utils/displayMode";
import { iOSPWABottomPadding } from "../config";
import { isiOS } from "../utils/device";

export interface FavoriteButtonProps {
  isFavorite: boolean;
  toggleFavorite: () => void;
}

function FavoriteButton(props: FavoriteButtonProps) {
  return (
    <Box
      role="presentation"
      // Place the button in the top right corner.
      sx={{
        position: "fixed",
        bottom: isPWA() && isiOS() ? 75 + iOSPWABottomPadding : 75,
        right: 20,
        zIndex: 10,
      }}
    >
      <Fab
        onClick={props.toggleFavorite}
        color="primary"
        aria-label="Add to favorites"
      >
        { props.isFavorite && <FavoriteIcon fontSize="medium" /> }
        { !props.isFavorite && <FavoriteBorderIcon fontSize="medium" /> }
      </Fab>
    </Box>
  );
}

export default FavoriteButton;
