import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TopBarContext } from '../TopBar';
import FavoriteButton from '../components/FavoriteButton';
import { Box, Chip, Container, Snackbar, Typography } from '@mui/material';
import { stringToColor, textColor } from '../utils/avatar';
import ShowItems from '../components/ShowItems';
import { Drug } from '../classes/drug';
import Progress from '../components/Progress';
import { capitalizeAllWords } from '../utils/strings';
import { ErrorDialogContext } from '../components/ErrorDialog';
import { useSystemState, useSystemStateDispatch } from '../SystemState';
import ObeseTable from '../components/ObeseTable';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { components } from '../utils/markdown';

export interface DrugDetailsProps {
  urlPrefix: string;
}

function DrugDetails(props: DrugDetailsProps) {
  const [ favorite, setFavorite ] = React.useState<boolean>(false);
  const [ snackBarState, setSnackBarState ] = React.useState<boolean>(false);
  const [ drug, setDrug ] = React.useState<Drug>();
  const [ children, setChildren ] = React.useState<Drug[]>([]);
  const [ parent, setParent ] = React.useState<Drug | undefined>(undefined);
  const [ className, setClassName ] = React.useState<string>('');
  const [ displayFavBtn, setDisplayFavBtn ] = React.useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();
  const state = useSystemState();
  const dispatch = useSystemStateDispatch();

  const topBarCtx = React.useContext(TopBarContext);
  const errorDialogCtx = React.useContext(ErrorDialogContext);

  const toggleFavorite = () => {
    if(drug) dispatch({ type: 'setFavorite', item: drug, add: !favorite});
    setFavorite(!favorite);
    setSnackBarState(true);
    setTimeout(() => {
      setSnackBarState(false);
    }, 3000);
  };

  React.useEffect(() => {
    topBarCtx?.setTitle('Dettagli Farmaco');
  }, []);

  React.useEffect(() => {
    // scroll to top on page load
    // this is needed because the page is not reloaded when the id in the url changes
    window.scrollTo({ top: 0 });
    setDrug(undefined);
    setChildren([]);

    state.drugs.then((drugs) => {
      const drug = drugs.getById(parseInt(params.id ?? ''));
      if(drug) {
        setDrug(drug);
        setChildren(drugs.getChildren(drug?.code));

        state.favorites.then((favorites) => {
          setFavorite(favorites.isFavorite(drug));
          setDisplayFavBtn(true);
        }).catch((err) => {
          errorDialogCtx?.setContent(err.message);
          errorDialogCtx?.setOpen(true);
        });
      } else {
        navigate('/404');
      }
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
    });
  }, [params.id]);

  React.useEffect(() => {
    if(drug?.ref) {
      state.drugs.then((drugs) => {
        const parent = drugs.getById(drug.ref);
        if(parent) {
          setParent(parent);
        }
      }).catch((err) => {
        errorDialogCtx?.setContent(err.message);
        errorDialogCtx?.setOpen(true);
      });
    }
  }, [drug?.ref]);

  React.useEffect(() => {
    if(drug?.class && drug.class !== '') {
      setClassName(capitalizeAllWords(drug.class));
    } else if(parent?.class && parent.class !== '') {
      setClassName(capitalizeAllWords(parent.class));
    } else {
      setClassName('Nessuna Classe');
    }
  }, [drug, parent]);

  if(!drug) {
    return <Progress inPage />
  }

  const chipBackgroundColor = stringToColor(className);
  const chipTextColor = textColor(chipBackgroundColor);

  return (
    <React.Fragment>
      { displayFavBtn && <FavoriteButton isFavorite={favorite} toggleFavorite={toggleFavorite} /> }
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarState}
        message={ favorite ? 'Aggiunto ai preferiti' : 'Rimosso dai preferiti' }
      />
      <Container maxWidth="md">
        <Box sx={{ my: 3 }}>
          <Chip style={
            {
              backgroundColor: chipBackgroundColor,
              color: chipTextColor,
            }
          } label={ className } />
          <Typography variant="h4">{ drug.title }</Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <table className="dosage">
            <tbody>
              { drug.dosage_normal &&
              <tr>
                <td>Dose Normale</td>
                <td>{ drug.dosage_normal }</td>
              </tr>
              }
              { drug.dosage_30_50 &&
              <tr>
                <td>Dose (egfr30-50)</td>
                <td>{ drug.dosage_30_50 }</td>
              </tr>
              }
              { drug.dosage_10_30 &&
              <tr>
                <td>Dose (egfr10-30)</td>
                <td>{ drug.dosage_10_30 }</td>
              </tr>
              }
            </tbody>
          </table>
        </Box>
        { drug.extra &&
        <Box sx={{ my: 2 }}>
          <ObeseTable />
        </Box>
        }
        { drug.note &&
        <Box sx={{ my: 2 }}>
          <Typography variant="h5">Note</Typography>
          <Markdown
            className={'markdown'}
            remarkPlugins={[remarkGfm]}
            components={components}>
            { drug.note }
          </Markdown>
        </Box>
        }
        <Box sx={{ my: 3 }}>
          <ShowItems items={children} urlPrefix={props.urlPrefix} />
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default DrugDetails;
