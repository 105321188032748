import React from 'react';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import ErrorDialog from './components/ErrorDialog';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Disclaimer from './components/Disclaimer';

function Layout() {
  const [disclaimerDialogOpen, setDisclaimerDialogOpen] = React.useState(localStorage.getItem('disclaimerAccepted') !== 'true');
  const handleDisclaimerDialogClose = () => {
    localStorage.setItem('disclaimerAccepted', 'true');
    setDisclaimerDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={disclaimerDialogOpen}
      >
        <DialogTitle>
          {"Note per gli utilizzatori"}
        </DialogTitle>
        <DialogContent>
          <Disclaimer />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDisclaimerDialogClose}>
            Ho letto e accetto
          </Button>
        </DialogActions>
      </Dialog>
      <TopBar />
      <Outlet />
      <Footer />
      <BottomBar />
      <ErrorDialog />
    </React.Fragment>
  );
}

export default Layout;
