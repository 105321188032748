import { CsvParser } from '../utils/csv';

export class AssignedNotes implements CsvParser {
    private items = new Map<number, number[]>();

    addItem(line: string[]) {
        if(line.length !== 2) {
            throw new Error(`Invalid CSV array length for assigned note: ${line.length}`);
        }

        const pathologyId = parseInt(line[0]);
        const noteId = parseInt(line[1]);

        const notes = this.items.get(pathologyId);
        notes ? notes.push(noteId): this.items.set(pathologyId, [noteId]);
    }

    getAssignedNotes(pathologyId: number): number[] {
        return this.items.get(pathologyId) ?? [];
    }
}