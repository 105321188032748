import { createContext, useContext, useReducer } from 'react';
import { ReactNode } from 'react';
import { ShowItem } from './classes/showItem';
import { parseCsv } from "./utils/csv";
import { Drugs } from "./classes/drug";
import { Pathologies } from "./classes/pathology";
import { Notes } from "./classes/note";
import { AssignedNotes } from "./classes/assignedNotes";
import { Favorites, loadFavorites } from "./classes/favorites";

export interface SystemDB {
  drugs: Promise<Drugs>;
  pathologies: Promise<Pathologies>;
  notes: Promise<Notes>;
  assignedNotes: Promise<AssignedNotes>;
}

export interface SystemState extends SystemDB {
  favorites: Promise<Favorites>;
}

export type SetFavorite = { type: 'setFavorite'; item: ShowItem, add: Boolean };
export type SystemStateAction = SetFavorite;

export function SystemStateProvider({ children }: { children: ReactNode }) {
    const [context, dispatch] = useReducer(
      stateReducer,
      initialState
    );
  
    return (
      <SystemStateContext.Provider value={context}>
        <SystemStateDispatchContext.Provider value={dispatch}>
          {children}
        </SystemStateDispatchContext.Provider>
      </SystemStateContext.Provider>
    );
}

export function useSystemState() {
  return useContext(SystemStateContext);
}

export function useSystemStateDispatch() {
  return useContext(SystemStateDispatchContext);
}

const db: SystemDB = {
  drugs: parseCsv("/db/drugs.csv", () => new Drugs()),
  pathologies: parseCsv("/db/pathologies.csv", () => new Pathologies()),
  notes: parseCsv("/db/notes.csv", () => new Notes()),
  assignedNotes: parseCsv("/db/assigned-notes.csv", () => new AssignedNotes()),
};

const initialState: SystemState = {
  ...db,
  favorites: loadFavorites(db)
};

const SystemStateContext = createContext<SystemState>(initialState);
const SystemStateDispatchContext = createContext<React.Dispatch<SystemStateAction>>(() => null);

function stateReducer(state: SystemState, action: SystemStateAction) {
  switch (action.type) {
    case 'setFavorite':
      state.favorites.then((favorites) => {
        action.add ? favorites.addFavorite(action.item) : favorites.removeFavorite(action.item);
      }).catch(() => {}); // Do nothing
      return state;
    default:
      return state;
  }
}