import { Accordion, AccordionDetails, AccordionSummary, Box, Container, InputBase, Toolbar, Typography, alpha, styled } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSearch } from '../hooks/useSearch';
import { ShowItem } from '../classes/showItem';
import ShowItems from '../components/ShowItems';
import Progress from '../components/Progress';
import { TopBarContext } from '../TopBar';
import { ErrorDialogContext } from '../components/ErrorDialog';
import { useSearchParams } from 'react-router-dom';
import Disclaimer from '../components/Disclaimer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SearchInput = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

let lastSearch = 0;

function Search() {
  const [search, setSearch] = React.useState<string | null>(null);
  const [results, setResults] = React.useState<ShowItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [handleSearch] = useSearch();

  const topBarCtx = React.useContext(TopBarContext);
  const errorDialogCtx = React.useContext(ErrorDialogContext);

  React.useEffect(() => {
    topBarCtx?.setTitle('Cerca');
  }, []);

  React.useEffect(() => {
    setLoading(true);
    handleSearch(search).then((res) => {
      setResults(res);
      setLoading(false);
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
      setLoading(false);
    });

    // if no new query comes in 1 second, set the search string as query parameter
    const n = lastSearch + 1;
    lastSearch = n;
    setTimeout(() => {
      if (n !== lastSearch) {
        // this search is outdated
        return;
      }

      // set the search string as query parameter
      if (search) {
        if (searchParams.has('q') && searchParams.get('q') === search) {
          return;
        }
        setSearchParams({ q: search });
      } else if (searchParams.has('q')) {
        setSearchParams({});
      }
    }, 1000);
  }, [search]);

  React.useEffect(() => {
    if (searchParams.has('q')) {
      setSearch(searchParams.get('q') as string);
    } else {
      setSearch(null);
    }
  }, [searchParams]);

  return (
    <React.Fragment>
        <Toolbar variant="dense" style={
        {
          background: 'var(--white)',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px',
        }
        }>
          <SearchInput onChange={
            (event) => {
              setSearch((event.target as HTMLInputElement).value);
            }
          }>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Cerca…"
              inputProps={{ 'aria-label': 'search', 'style': { width: '100%' }, 'value': search ?? '' }}
              style={{ width: '100%' }}
            />
          </SearchInput>
        </Toolbar>
      <Container>
        <Box sx={{ my: 2 }}>
          { results.length === 0 && !loading &&
          <React.Fragment>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Note per gli utilizzatori</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Disclaimer />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Altro</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  Serve aiuto? Contattaci all'indirizzo email <a href="mailto:help@asclepeion.it">help@asclepeion.it</a> o scarica il <a href="/pdf/manuale.pdf" target='_blank' rel='noopener'>manuale in formato PDF</a>.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
          }
          { loading && <Progress />}
          <ShowItems items={results} urlPrefix='/search' showScrollToTop />
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default Search;
