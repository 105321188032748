import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Note } from '../classes/note';
import { components } from '../utils/markdown';

export interface NoteListProps {
  notes: Note[];
}

function NoteList(props: NoteListProps) {
  const { notes } = props;

  return (
    <React.Fragment>
      {notes.map((note: Note) => (
        <Accordion key={note.title}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{note.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Markdown
              className={'markdown'}
              remarkPlugins={[remarkGfm]}
              components={components}>
              {note.description}
            </Markdown>
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
}

export default NoteList;
