import { Box, Typography } from "@mui/material";
import React from "react";

function ObeseTable() {
  return <React.Fragment>
    <Box sx={{ my: 1 }}>
      <Typography variant="h6">Pazienti Non Obesi</Typography>
      <Typography variant="body2">Utilizzare il Peso Ideale a meno che il Peso Attuale sia inferiore</Typography>
    </Box>
    <Box sx={{ my: 1 }}>
      <Typography variant="h6">Pazienti Obesi (Peso &gt;30% rispetto al Peso Ideale)</Typography>
      <Typography variant="body2">
        Utilizzare il Peso Corretto:<br />
        <strong>Peso Corretto = Peso Ideale + 0.4 x (Peso Attuale - Peso Ideale)</strong>
      </Typography>
    </Box>
  </React.Fragment>;
}

export default ObeseTable;
