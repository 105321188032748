import { ShowItem, ItemManager } from "./showItem";
import { ReactNode } from 'react';
import MedicationIcon from '@mui/icons-material/Medication';

export class Drug implements ShowItem {
    code: number;
    title: string;
    ref: number;
    class: string;
    note: string;
    dosage_normal: string;
    dosage_30_50: string;
    dosage_10_30: string;
    extra: boolean;

    constructor(csvArray: string[]) {
        if(csvArray.length !== 9) {
            throw new Error(`Invalid CSV array length for Drug: ${csvArray.length}`);
        }

        this.code = parseInt(csvArray[0]);
        this.title = csvArray[3];
        this.ref = parseInt(csvArray[1]);
        this.class = csvArray[2];
        this.note = csvArray[4];
        this.dosage_normal = csvArray[5];
        this.dosage_30_50 = csvArray[6];
        this.dosage_10_30 = csvArray[7];
        this.extra = csvArray[8] === "1";
    }

    url(): string {
        return `/drug/${this.code}`;
    }

    render(): string {
        return "";
    }

    icon(): ReactNode {
        return <MedicationIcon />
    }

    id(): string {
        return `drug-${this.code}`;
    }
}

export class Drugs implements ItemManager<Drug> {
    private items: Drug[] = [];

    addItem(line: string[]) {
        const drug = new Drug(line);
        this.items.push(drug);
    }
    
    getItems(): Drug[] {
        return this.items.filter(item => isNaN(item.ref));
    }

    getById(id: number): Drug | undefined {
        return this.items.find(item => item.code === id);
    }

    getChildren(parentId: number): Drug[] {
        return this.items.filter(item => item.ref === parentId);
    }
}