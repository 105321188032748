import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TopBarContext } from '../TopBar';
import { Box, Container, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Progress from '../components/Progress';
import { Note } from '../classes/note';
import { ErrorDialogContext } from '../components/ErrorDialog';
import { useSystemState } from '../SystemState';
import { components } from '../utils/markdown';

function NoteDetails() {
  const [ note, setNote ] = React.useState<Note>();

  const params = useParams();
  const navigate = useNavigate();
  const state = useSystemState();

  const topBarCtx = React.useContext(TopBarContext);
  const errorDialogCtx = React.useContext(ErrorDialogContext);

  React.useEffect(() => {
    topBarCtx?.setTitle('Dettagli Nota');
  }, []);

  React.useEffect(() => {
    // scroll to top on page load
    // this is needed because the page is not reloaded when the id in the url changes
    window.scrollTo({ top: 0 });
    setNote(undefined);

    state.notes.then((notes) => {
      const note = notes.getById(parseInt(params.id ?? ''));
      if(note) {
        setNote(note);
      } else {
        navigate('/404');
      }
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
    });
  }, [params.id]);

  if(!note) {
    return <Progress inPage />
  }

  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Box sx={{ my: 3 }}>
          <Typography variant="h4">{ note.title }</Typography>
          { note.description &&
          <Markdown
            className={'markdown'}
            remarkPlugins={[remarkGfm]}
            components={components}>
              { note.description }
          </Markdown>
          }
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default NoteDetails;
