import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { ShowItem } from '../classes/showItem';
import { Link } from 'react-router-dom';
import { resultAvatar } from '../utils/avatar';
import ScrollToTop from './ScrollToTop';

export interface ItemsProps {
  items: ShowItem[];
  urlPrefix: string;
  showScrollToTop?: boolean;
}

const joinPath = (...path: string[]) => path.join('/').replace(/\/+/g, '/');

function ShowItems(props: ItemsProps) {
  const { items, urlPrefix } = props;

  if (items.length === 0) {
    return (
      <React.Fragment></React.Fragment>
    );
  }

  const elements = items.map((item: ShowItem) => {
    return (
      <Link to={joinPath(urlPrefix, item.url())} style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem alignItems="flex-start" className='background-pink'>
          <ListItemAvatar>
            <Avatar {...resultAvatar(item)}>
              { item.icon() }
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="rgba(0, 0, 0, 0.7)"
                  fontSize={20}
                  fontWeight={600}
                >
                  {item.title}
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                {item.render()}
              </React.Fragment>
            }
          />
        </ListItem>
      </Link>
    );
  }).reduce((prev, curr) => (
    <>
      {prev}
      <Divider variant="inset" component="li" />
      {curr}
    </>
  ));

  return (
    <React.Fragment>
      <List sx={{ width: '100%' }} className='background-pink'>
        { elements }
      </List>
      { props.showScrollToTop && <ScrollToTop /> }
    </React.Fragment>
  );
}

export default ShowItems;
