import { Typography } from "@mui/material";
import React from "react";

function Disclaimer() {
  return (
    <React.Fragment>
      <Typography variant="h6">
        Note Generali
      </Typography>
      <Typography variant="body2" component="div">
        Le indicazioni contenute in questo documento sono condivise da infettivologi e formulate per gruppi di pazienti con diagnosi sindromiche allo scopo di uniformare le diverse linee guida locali emerse nel corso della prima discussione sul Manuale Regionale, con la finalità di costruire insieme un programma di stewardship condivisa.
      </Typography>
      <Typography variant="body2" component="div">
        Il Manuale è uno strumento di consultazione e le decisioni nel singolo paziente vanno prese dal medico curante, anche adattando le indicazioni di questo documento con il giudizio clinico, l'epidemiologia locale, la logistica e le preferenze dei pazienti.
        Qualora il medico si discosti in modo significativo dalle indicazioni fornite dal documento, si raccomanda di consultare il consulente infettivologo. I dati di sensibilità locali agli antibiotici potrebbero inoltre indicare scelte di terapia empirica differenti da quelle proposte dai documenti internazionali.
        Per quanto riguarda inoltre il dosaggio dei farmaci nei pazienti con pesi estremi e con insufficienza epatica o renale, soprattutto se molto grave (eGFR &lt;10 ml/min, dialisi, CVVH), si raccomanda di consultare il materiale informativo dei singoli farmaci, i consulenti nefrologo, farmacista e infettivologo.
      </Typography>
      <Typography variant="h6">
        Note su Sepsi
      </Typography>
      <Typography variant="body2" component="div">
        in questo documento viene utilizzata prevalentemente la definizione di SEPSI I-II basata sui criteri SIRS; La nuova definizione (SEPSI-3) non è stata ancora introdotta. In accordo, le definizioni usate nelle tabelle (talvolta omettendo la sepsi grave) sono:

        <ul>
          <li>
            Sepsi = due o più criteri SIRS (T&gt; 38°C o &lt;36°C, GB &gt;12000, Fc &gt;90/m', Fresp &gt;20/m') + sospetta causa infettiva
          </li>
          <li>
            Sepsi Grave = Sepsi + danno d'organo (Lattato &gt;2 o PAO &lt;90 o SatO2 &lt;90 o ridotta diuresi o alterazione di INR/Creatinina/Bilirubina/PLTs)
          </li>
          <li>
            Shock Settico = Sepsi Grave che non ha risposto al trattamento con fluidi (cioè necessita di amine)
          </li>
        </ul>
      </Typography>
      <Typography variant="h6">
        Note alle tabelle
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Note sul "Paziente Allergico alla Penicillina"</strong> (vedi anche tabella a pag. 34): nelle tabelle, la colonna "Allergia alla Penicillina" è stata suddivisa in <strong>"Allergia Lieve"</strong> e <strong>"Allergia Grave"</strong>; tale distinzione è analoga a quella fatta al John Hopkins Hospital (Antibiotic Guidelines 2015-2016; <a href="http://www.hopkinsmedicine.org/amp/guidelines/Antibiotic_guidelines.pdf" target="_blank" rel="noopener"> Treatment Recommendations For Adult Inpatients</a>).
        I pazienti con "Allergia Lieve" hanno un basso rischio di avere una reazione allergica grave se trattati con cefalosporine, anche di ultima generazione o con i carbapenemici; per i pazienti con "Allergia Grave" è sempre più prudente una strategia con farmaci alternativi ai beta-lattamici (chinolonici, aminoglicosidi, tetracicline, vancomicina, linezolid, daptomicina).
        Per tutte le tabelle, si considera <strong>Allergia grave</strong> se reazione precoce (&lt;1 ora), con orticaria, angioedema, flushing, broncospasmo, edema laringeo, vomito/diarrea, ipotensione. Si possono considerare <strong>non gravi</strong> le forme ritardate (&gt;1 ora), senza le caratteristiche di quelle gravi e con interessamento solo cutaneo (rash maculopapulare/morbilliforme).
        Il rischio di cross-reattività tra cefalosporine di III-IV generazione con i carbapenemici è basso.
      </Typography>
      <Typography variant="body2" component="div">
        Questa strategia preserva l'utilizzo dei beta-lattamici nei pazienti a basso rischio di reazione allergica; le alternative ai beta-lattamici possono presentare delle criticità quali le resistenze (E. coli isolato da emocolture è resistente ai chinolonici fino al 40-50% dei casi) o la tossicità (es aminoglicosidi).
      </Typography>
      <Typography variant="body2" component="div">
        <strong>
        Tuttavia, in caso di anamnesi dubbia si consiglia una terapia senza beta-lattamici, consultando allergologo e infettivologo.
        </strong>
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Nota 1: fattori di rischio per MRSA:</strong> ricovero prolungato, uso prolungato di antibiotici, pazienti proveniente da rianimazione, emodialisi, ferita chirurgica, lesioni da decubito, CVC o altro hardware impiantato, abuso di sostanze ev, storia di infezione/colonizzazione da MRSA, polmonite necrotizzante/cavitazione, shock settico, colturale su materiale respiratorio positivo, polmonite in corso di influenza, età avanzata.
      </Typography>
      <Typography variant="body2" component="div">
        Molecole con attività anti MRSA: vancomicina, teicoplanina, linezolid, daptomicina, dalbavancina, oritavancina, tedizolid, ceftarolina, ceftobiprole.
      </Typography>
      <Typography variant="body2" component="div">
        Dati di letteratura e di cinetica riportano una minor efficacia clinica di vancomicina nei pazienti con batteriemia da MRSA quando la MIC risulta &gt;1 mg/dl: in questi casi, la scelta di vancomicina va accompagnata dal monitoraggio delle concentrazioni plasmatiche e della funzionalità renale al fine di ottimizzare l'efficacia del trattamento e ridurne la tossicità.
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Nota 2: fattori di rischio per ESBL:</strong> uso prolungato di antibiotici ad ampio spettro, provenienti da reparti con elevata incidenza di MDR (terapia intensiva), colonizzati o recente infezione con EBSL+, età avanzata, portatori di CV, pielostomie, drenaggi biliari e frequente uso di antibiotici, età avanzata.
        Nell'ambito della migliore efficacia del meropenem nella terapia delle BSI (MERINO trial), particolare dedizione va usata sulle strategie di risparmio dei carbapenemi, soprattutto durante la fase empirica della terapia, ad esempio, utilizzando le nuove molecole o terapie di associazione.
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Nota 3: fattori di rischio per CRE:</strong> età avanzata, colonizzazione nota, pregressa infezione da CRE, provenienza o degenza in reparti ad elevata incidenza di CR (&gt;20%), recente trattamento con carbapenemi.
        Le molecole utili sono: valutare ceftazidime/avibactam (attività anti-KPC e -OXA 48), meropenem/vaborbactam (anti-KPC), cefiderocol (include attività anti MBL; attività anche contro Acinetobacter spp. MDR)
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Nota 4: fattori di rischio per P. aeruginosa:</strong> età avanzata, BPCO grave e/o bronchiettasie e uso frequente di steroidi e antibiotici, recente intubazione, immunocompromessione grave. Valutare ceftolozane/tazobactam, ceftazidime/avibactam, cefiderocol.
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Nota 5: ciprofloxacina,</strong> attenzione se recente (ultimo mese) o frequente uso di chinolonici o età &gt;59 aa; monoterapia; convulsioni/epilessia; tendiniti.
      </Typography>
    </React.Fragment>
  );
}

export default Disclaimer;
