import { ShowItem } from "../classes/showItem";
import { useSystemState } from '../SystemState';

export function useSearch() {
  const state = useSystemState();

  function handleSearch(e: string | null): Promise<ShowItem[]> {
    return new Promise(async (resolve, reject) => {
      let res: ShowItem[] = [];
      if (!e || e.length < 2) {
        res = [];
      } else {
        try {
          res = res.concat(
              (await state.drugs).getItems(), 
              (await state.pathologies).getItems()
            )
            .filter(
              (item: ShowItem) => item.title.toLowerCase().includes(e.toLowerCase())
            );
        } catch (e) {
          reject(e);
        }
      }

      resolve(res);
    });
  }

  return [handleSearch];
}
