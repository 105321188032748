import React from 'react';
import { ShowItem } from '../classes/showItem';
import Progress from '../components/Progress';
import ShowItems from '../components/ShowItems';
import { TopBarContext } from '../TopBar';
import { ErrorDialogContext } from '../components/ErrorDialog';
import { useSystemState } from '../SystemState';

function Favorites() {
  const [favorites, setFavorites] = React.useState<ShowItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const state = useSystemState();

  const topBarCtx = React.useContext(TopBarContext);
  const errorDialogCtx = React.useContext(ErrorDialogContext);

  React.useEffect(() => {
    topBarCtx?.setTitle('Preferiti');
  }, []);

  React.useEffect(() => {
    setLoading(true);
    state.favorites.then((res) => {
      setFavorites(res.getItems());
      setLoading(false);
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      { loading && <Progress top={'80px'} />}
      <ShowItems items={favorites} urlPrefix='/favorites' />
    </React.Fragment>
  );
}

export default Favorites;
