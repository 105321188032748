import { Box, Typography } from '@mui/material';
import React from 'react';
import { TopBarContext } from '../TopBar';

function NoPage() {
  const topBarCtx = React.useContext(TopBarContext);

  React.useEffect(() => {
    topBarCtx?.setTitle('Pagina non trovata');
  }, []);

  return (
    <Box>
      <Typography variant="h1" component="div">
        404
      </Typography>
      <Typography variant="h2" component="div">
        Pagina non trovata
      </Typography>
    </Box>
  );
}

export default NoPage;
