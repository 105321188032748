import React from "react";
import { ExtraProps } from "react-markdown";
import { Link } from "react-router-dom";

export const components = {
  a(props: React.ClassAttributes<HTMLAnchorElement> & React.AnchorHTMLAttributes<HTMLAnchorElement> & ExtraProps) {
    const {children, href} = props;
    if (!href) {
      return <a {...props}>{children}</a>;
    }
    return (<Link to={href}>{children}</Link>);
  },
};
