import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TopBarContext } from '../TopBar';
import { Box, Container, Snackbar, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ShowItems from '../components/ShowItems';
import NoteList from '../components/NoteList';
import FavoriteButton from '../components/FavoriteButton';
import { Pathology } from '../classes/pathology';
import Progress from '../components/Progress';
import { ErrorDialogContext } from '../components/ErrorDialog';
import { useSystemState, useSystemStateDispatch } from '../SystemState';
import { Note } from '../classes/note';
import { components } from '../utils/markdown';

export interface PathologyDetailsProps {
  urlPrefix: string;
}

function PathologyDetails(props: PathologyDetailsProps) {
  const [ favorite, setFavorite ] = React.useState<boolean>(false);
  const [ snackBarState, setSnackBarState ] = React.useState<boolean>(false);
  const [ pathology, setPathology ] = React.useState<Pathology>();
  const [ children, setChildren ] = React.useState<Pathology[]>([]);
  const [ notes, setNotes ] = React.useState<Note[]>([]);
  const [ displayFavBtn, setDisplayFavBtn ] = React.useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();
  const state = useSystemState();
  const dispatch = useSystemStateDispatch();

  const topBarCtx = React.useContext(TopBarContext);
  const errorDialogCtx = React.useContext(ErrorDialogContext);

  const toggleFavorite = () => {
    if(pathology) dispatch({ type: 'setFavorite', item: pathology, add: !favorite});
    setFavorite(!favorite);
    setSnackBarState(true);
    setTimeout(() => {
      setSnackBarState(false);
    }, 3000);
  };

  React.useEffect(() => {
    topBarCtx?.setTitle('Dettagli Patologia');
  }, []);

  React.useEffect(() => {
    // scroll to top on page load
    // this is needed because the page is not reloaded when the id in the url changes
    window.scrollTo({ top: 0 });
    setPathology(undefined);
    setChildren([]);
    setNotes([]);

    state.pathologies.then((pathologies) => {
      const pathology = pathologies.getById(parseInt(params.id ?? ''));
      if(pathology) {
        setPathology(pathology);
        setChildren(pathologies.getChildren(pathology?.code));

        state.favorites.then((favorites) => {
          setFavorite(favorites.isFavorite(pathology));
          setDisplayFavBtn(true);
        }).catch((err) => {
          errorDialogCtx?.setContent(err.message);
          errorDialogCtx?.setOpen(true);
        });
      } else {
        navigate('/404');
      }
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
    });
  }, [params.id]);

  React.useEffect(() => {
    if (!pathology) {
      return;
    }

    state.assignedNotes.then(async (assignedNotes) => {
      try {
        const notes = await state.notes;
        const n: Note[] = [];
        assignedNotes.getAssignedNotes(pathology?.code).
          map((noteId) => notes.getById(noteId)).
          forEach((note) => {
            if (note) {
              n.push(note);
            }
          });
        setNotes(n);
      } catch (err: any) {
        errorDialogCtx?.setContent(err.message);
        errorDialogCtx?.setOpen(true);
      }
    }).catch((err) => {
      errorDialogCtx?.setContent(err.message);
      errorDialogCtx?.setOpen(true);
    });
  }, [pathology]);

  if(!pathology) {
    return <Progress inPage />
  }

  return (
    <React.Fragment>
      { displayFavBtn && <FavoriteButton isFavorite={favorite} toggleFavorite={toggleFavorite} /> }
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarState}
        message={ favorite ? 'Aggiunto ai preferiti' : 'Rimosso dai preferiti' }
      />
      <Container maxWidth="md">
        <Box sx={{ my: 3 }}>
          <Typography variant="h4">{ pathology.title }</Typography>
          { pathology.caption &&
          <Typography variant="h6">{ pathology.caption }</Typography>
          }
          { pathology.description &&
          <Markdown
            className={'markdown'}
            remarkPlugins={[remarkGfm]}
            components={components}>
              { pathology.description }
          </Markdown>
          }
        </Box>
        { children && children.length > 0 &&
        <Box sx={{ my: 3 }}>
          <ShowItems items={children} urlPrefix={props.urlPrefix} />
        </Box>
        }
        { notes && notes.length > 0 &&
        <Box sx={{ my: 3 }}>
          <Typography variant="h5">Note</Typography>
          <Box sx={{ my: 1 }}>
            <NoteList notes={notes} />
          </Box>
        </Box>
        }
      </Container>
    </React.Fragment>
  );
}

export default PathologyDetails;
